import { Credentials } from "auth/models/credentials";

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export interface login_request {
    type: typeof LOGIN_REQUEST,
    payload: {
        credentials: Credentials
    }
}

export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
export interface set_login_error {
    type: typeof SET_LOGIN_ERROR,
    payload:{error: any}
}

export type LoginActionsTypes = login_request | set_login_error;