import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { NavBar } from 'shared/components/nav-bar';
import FooterBar from 'shared/components/footer-bar';
import QB from 'assets/images/QB.png';
import QP from 'assets/images/QP.png';
import QE from 'assets/images/QE.png';
import QU from 'assets/images/QU.png';
import logo from 'assets/images/logoSGP.png';
import { StyledInputSearch, StyledSearchIconDiv, StyledSearchDiv, StyledLink } from './styles';

import './index.scss'
import { IMainModuleState } from 'main/store/reducers/types';
import { useHistory } from 'react-router-dom';
import { getView, theme } from 'shared/constants';
import Radio from '@material-ui/core/Radio/Radio';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/*eslint-disable*/
interface IProps {
    heightHeader?:string,
} 

const Layout:React.FC<IProps>  = ({children, heightHeader = '24px'}) => {

  const isBigDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const isDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const isPad = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  let view = getView();

  const searchState = useSelector((state:IMainModuleState) => state.searchState);
  const getByIdState = useSelector((state:IMainModuleState) => state.getByIdState);
  const getPublication = useSelector((state:IMainModuleState) => state.paramsState.publication);

  const dispatch = useDispatch();
  const logout = useCallback(
    () => dispatch({ type: 'LOGOUT' }),
    [dispatch]
  )

  const set_publication = useCallback(
    (publication:string) => dispatch({ type: 'SET_PUBLICATION', payload: {publication} }),
    [dispatch]
  )

  const latest_request = useCallback(
    () => dispatch({ type: 'GETLATEST_REQUEST'}),
    [dispatch]
  )

  const search_request = useCallback(
    (text:string, init?: boolean, go?:boolean) => dispatch({ type: 'SEARCH_REQUEST', payload: {text, init, go} }),
    [dispatch]
  )

   useEffect(()=>{
    inputRef.current!.focus();
  },[])


  const inputRef:React.RefObject<HTMLInputElement> = React.createRef();
  const handleIconButton = () => {
    //if (inputRef.current!.value && inputRef.current!.value.length>0) {
      search_request(inputRef.current!.value, true);
    //}
    inputRef.current!.focus();
  }
  const keyPressed = (event: any) => {
    if (event.key === "Enter") {
      handleIconButton()
    }
  }

  const getWidth = () => {
    if (isMobile) {
      return '99%'
    } else if(isPad) {
      return '94%'
    } else if(isDesktop) {
      return '80%'
    } else if(isBigDesktop){
      return '65%'
    } else {
      return '50%'
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    set_publication((event.target as HTMLInputElement).value);
    latest_request();
    if (searchState.searchTxt) search_request(inputRef.current!.value, true, false);
    else if (history.location.pathname.startsWith('/main/article/')) history.replace('/main/latest');
    
  };
  
  const checkActive = (path: string) => {
    return history.location.pathname.indexOf(path)>-1 ? 'active':'';
  }

  return (
      <>
          <NavBar height={heightHeader} logout={logout}></NavBar>
          <div className="App">
            <header className="App-header">
              {view === "QB" &&
              <>
                <img src={QB} className="App-logo" alt="logo" style={{padding:8, width:300}}/>
                <span style={{color:'#d2b982', fontSize:'95%', textShadow:'0.5px 0.5px #FAFAFA, -0.5px -0.5px #444'}}>Quotidien d'information, de documentation et de prospective</span>
              </>
              }

              {view === "QP" &&
              <>
                <img src={QP} className="App-logo" alt="logo" style={{padding:8, width:500}}/>
              </>
              }

              {view === "QE" &&
              <>
                <img src={QE} className="App-logo" alt="logo" style={{padding:8, width:500}}/>
              </>
              }

              {view === "QU" &&
              <>
                <img src={QU} className="App-logo" alt="logo" style={{padding:8, width:500}}/>
              </>
              }

              {view === "SGP" &&
              <>
                <img src={logo} className="App-logo" alt="logo" style={{padding:8, width:300}}/>
              </>
              }

            </header>

          
            <Paper style={{backgroundColor:'#f5f5f5', width:getWidth(), margin:'auto', marginTop:16,  padding:0}}>
              <StyledSearchDiv>
                <StyledInputSearch
                  inputRef={inputRef}
                  placeholder="Recherche ..."
                  inputProps={{ 'aria-label': 'search' }}
                  onKeyPress={keyPressed}
                />
                <StyledSearchIconDiv >
                  <IconButton aria-label="dispatch serch" color="inherit" style={{'padding':'4px'}} onClick={handleIconButton}>
                    <SearchIcon />
                  </IconButton>
                </StyledSearchIconDiv>
              </StyledSearchDiv>
              {view === "SGP" &&
                <div style={{textAlign:'left', paddingLeft:8, backgroundColor:'rgb(93, 64, 55)'}}>
                  <RadioGroup aria-label="position" name="position" value={getPublication} onChange={handleChange} row>
                    <FormControlLabel
                      value="QB"
                      control={<Radio color="primary"  size="small" style={{color:'#FAFAFA'}} />}
                      label="BQ"
                      style={{color:'#FAFAFA'}}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="QP"
                      control={<Radio color="primary" size="small" style={{color:'#FAFAFA'}}/>}
                      label="CP"
                      style={{color:'#FAFAFA'}}
                      labelPlacement="end"
                    />
                     <FormControlLabel
                      value="QE"
                      control={<Radio color="primary" size="small" style={{color:'#FAFAFA'}}/>}
                      label="CE"
                      style={{color:'#FAFAFA'}}
                      labelPlacement="end"
                    />
                     <FormControlLabel
                      value="QU"
                      control={<Radio color="primary" size="small" style={{color:'#FAFAFA'}}/>}
                      label="CU"
                      style={{color:'#FAFAFA'}}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </div>
              }
              <div style={{textAlign:'left', padding:8}}>
                  <StyledLink to={'/main/latest/'} tabIndex={-1} isactive={checkActive('/main/latest')}> 
                    Dernières publications
                  </StyledLink> 
                  {searchState.searchTxt &&
                  <>
                    <span style={{padding:4, color:'rgba(0, 0, 0, 0.27)'}}>•</span>
                    <StyledLink to={'/main/search/'} tabIndex={-1} isactive={checkActive('/main/search')}> 
                      Recherche
                    </StyledLink>
                  </>
                  }
                  {getByIdState.key &&
                  <>
                    <span style={{padding:4, color:'rgba(0, 0, 0, 0.27)'}}>•</span>
                    <StyledLink to={'/main/article/'+getByIdState.key} tabIndex={-1} isactive={checkActive('/main/article')}> 
                      Article
                    </StyledLink> 
                  </>
                  }
              </div>
              {children}
              </Paper>
          </div>
          <FooterBar></FooterBar>
      </>
    );
}

export default Layout;