import { GetByIdState } from './types';
import * as getByIdActions from '../actions/getbyid/types';

const initialState : GetByIdState = {
    loading: false,
};

const getbyIdReducer = (state = initialState, action: getByIdActions.ActionsTypes): GetByIdState  => {

  switch (action.type) {
    
    case getByIdActions.INIT:
      return {
        ...state,
        loading: true,
        key:undefined
    };

    case getByIdActions.GETBYID_REQUEST:
      return {
        ...state,
        loading: true,
        key:action.payload.key
    };

    case getByIdActions.GETBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        article:action.payload.article
    };
  

    default:
      return state;
  }
};

export default getbyIdReducer;