import { IAuthModuleState, AuthState } from 'auth/store/reducers/types';
import { createSelector} from 'reselect'

const selectAuthState = (state:IAuthModuleState) => state.authState;

export const isLogged = createSelector<IAuthModuleState, AuthState, boolean>(
    selectAuthState,
    (state: AuthState) => state.token !== undefined
);

