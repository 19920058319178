import React from 'react';
import { ModalContent, StyledDialog, ModalText } from './style';
import LogoAnimated from 'shared/components/logo-animated';

interface IProps {
  pending:boolean
}

const Waiting :React.FC<IProps> = ({pending}) => {
    return (
        <StyledDialog aria-labelledby="simple-dialog-title" open={pending}>
            <ModalContent>
                <LogoAnimated/>
                <ModalText>{'... Chargement ...'}</ModalText>
            </ModalContent>
        </StyledDialog>
    )
}

export default Waiting;
