
import React from 'react';
import { withToastManager } from 'react-toast-notifications';
import { set_error } from 'core/store/actions/Error/errorActions';

interface IProps {
    error?:string,
    toastManager:any,
    set_error?:typeof set_error
}

  
class ToastManager extends React.Component<IProps> {

      getSnapshotBeforeUpdate(prevProps:IProps) {
        if (prevProps.error !== this.props.error) {
            return this.props.error ? {error:this.props.error} : null;
        }
        return null;
      }

      componentDidUpdate(props:IProps,state:any, snapshot:any){
       
        if (!snapshot) return;

        const { toastManager } = props;
        const { error } = snapshot;
        if (error) {
            console.log('toastManager:add')
            toastManager.add(`${error}`, {
                appearance: 'error',
                autoDismiss: true,
            });
            if (this.props.set_error) this.props.set_error('');
        }
    }

    render() {
      return null;
    }
  }

export default withToastManager(ToastManager);