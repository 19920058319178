import { keyframes } from "styled-components";
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Inconsolata',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

declare global {
    interface Window {
      /** documentation on foo */
      _env_: any;
    }
}

export const Constants = {
    isDev: process.env.NODE_ENV !== 'production',
    baseUrl: process.env.REACT_APP_PUBLIC_URL || process.env.PUBLIC_URL || "/",
    apiIdentity: (window._env_ && window._env_.IDENTITY_URL) || 'http://localhost:5000/api',
    apiArticles: (window._env_ && window._env_.ARTICLES_URL) || 'http://localhost:5004/api',
    wwwBQ: (window._env_ && window._env_.BQ_URL),
    wwwCP: (window._env_ && window._env_.CP_URL),
    wwwCE: (window._env_ && window._env_.CE_URL),
    wwwCU: (window._env_ && window._env_.CU_URL),
    wwwSGP: (window._env_ && window._env_.SGP_URL) || 'localhost',
    backGroundColor : '#333',
    colorText:'#FAFAFA',
    colorPrimary:'rgb(93, 64, 55)',
    colorRed:'rgb(211, 47, 47)',
    colorRedDarker:'#C01616',
    colorRedActive:'#F31E1E',
    colorRedLight:'rgba(211,47,47,.2)',
    colorBgFooter:'rgb(33, 33, 33)',
    colorLinkFooter:'rgba(255, 255, 255, 0.87)',
    colorTextFooter:'rgba(255, 255, 255, 0.54)',
    colorTextHeader:'rgb(250, 250, 250)',
    colorTitleHeader:'rgba(255, 255, 255, 0.87)',
    colorblink: keyframes`
        0% { color:#FAFAFA;  }
        50% { color:#AAAAAA; }
    `,
}

export const getView = () => {
    return  window.location.hostname === Constants.wwwBQ ? "QB":
            window.location.hostname === Constants.wwwCP ? "QP":
            window.location.hostname === Constants.wwwCE ? "QE":
            window.location.hostname === Constants.wwwCU ? "QU":
            "SGP"
  ;
}