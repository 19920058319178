import { SET_ERROR,CHECK_ERROR, ErrorActionsTypes } from "./errorTypes";

export function set_error(error: any, message?:string): ErrorActionsTypes {
    return {
      type: SET_ERROR,
      payload : { error, message }
    }
}

export function check_error(error: any): ErrorActionsTypes {
  return {
    type: CHECK_ERROR,
    payload : { error }
  }
}