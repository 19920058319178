import { Constants } from 'shared/constants';
import Axios from 'axios';
import qs from 'qs';
import { User } from 'auth/models/user';
import { Credentials } from 'auth/models/credentials';
import { Token } from 'auth/models/token';

export default class AuthService {

    public static async login({username, password, remember}: Credentials): Promise<Token> {

        return Axios.post(Constants.apiIdentity + '/authorize/login', 
          {username, password}
        )
        .then((result) => {
            if (result.status === 200) {
                return Promise.resolve(result.data);
              } else {
                throw  new Error("Vérifier le login ou le mot de passe");
              }
        });
    }

    public static async refreshToken(token: Token): Promise<Token> {
      return Axios.post(Constants.apiIdentity + '/authorize/refreshtoken', 
        {accessToken:token.accessToken.token, refreshToken:token.refreshToken}
      )
      .then((result) => {
          if (result.status === 200) {
              return Promise.resolve(result.data);
            } else {
              throw  new Error("Token non valable");
            }
      });
  }

}