import { SortType } from 'main//models/Sort-Type';
import { Article } from "main/models/Article";

export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export interface search_request {
    type: typeof SEARCH_REQUEST,
    payload: {
        text: string,
        init?: boolean,
        go?:boolean
    }
}

export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export interface search_success {
    type: typeof SEARCH_SUCCESS,
    payload: {
        page:number,
        results: Article[],
    }
}

export const MORE_RESULTS = 'MORE_RESULTS'
export interface more_results {
    type: typeof MORE_RESULTS,
}


export const CHANGE_SORT = 'CHANGE_SORT'
export interface change_sort {
    type: typeof CHANGE_SORT,
    payload: {
        sort:SortType,
    }
}



export type ActionsTypes = search_request | search_success | more_results | change_sort;