import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Constants } from 'shared/constants';

export const StyledAppBar = withStyles({
    root: {
      background: Constants.colorPrimary,
    }
})(AppBar);


export const StyledGrid = styled(Grid)<{height: string}>`
  min-height: ${props => (props.height)};
  padding:0px 8px;
`

export const StyledDiv = styled.div`
    flexGrow: 1
`;

export const StyledNavLink = styled.a`
  color: ${Constants.colorLinkFooter};
`;