import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import * as authRoutes from './authRoutes'
import LayoutAuth from './layout';

export const AuthModuleUrl = "/auth";

export const AuthModule =  () => (
  <LayoutAuth>
    <Switch>
      <Route exact path={`${AuthModuleUrl}/login`} component={authRoutes.Login} />
      <Redirect from={`${AuthModuleUrl}`}  to={`${AuthModuleUrl}/login`}/>
    </Switch>
  </LayoutAuth>
)