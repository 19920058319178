import { AccessToken } from "auth/models/token";

export const INIT = 'INIT'
export interface init {
    type: typeof INIT
}

export const INIT_END = 'INIT_END'
export interface init_end {
    type: typeof INIT_END
}

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export interface refresh_token {
    type: typeof REFRESH_TOKEN
}

export const LOGOUT = 'LOGOUT'
export interface logout {
    type: typeof LOGOUT
}

export type AuthActionsTypes = init | init_end | refresh_token |  logout;