import React from 'react';
import './index.scss'

import { StyledAuthContent } from './styles';
import { NavBar } from 'shared/components/nav-bar';
import FooterBar from 'shared/components/footer-bar';

/*eslint-disable*/
interface IProps {
    heightHeader?:string,
    heightFooter?:string
} 
const LayoutAuth:React.FC<IProps>  = ({children, heightHeader = '56px', heightFooter = '24px'}) => (
    <>
        <StyledAuthContent marginTop={heightHeader}>
          {children}
        </StyledAuthContent>
        <FooterBar height={heightFooter} fixed={true}></FooterBar>
    </>
  );
  
  export default LayoutAuth;