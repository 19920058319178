import React from 'react';
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createStore } from "redux-dynamic-modules";
import { applyMiddleware } from 'redux';
import { getSagaExtension } from "redux-dynamic-modules-saga";

import './index.scss';
import './index.d';

import { CoreStore } from 'core/store';
import { AuthStore } from 'auth/store';


import { logger } from 'core/middlewares/logger';
import { crashReporter } from 'core/middlewares/crashReporter';
import { timeoutScheduler } from 'core/middlewares/timeoutScheduler';
import history from 'auth/config/history';
import App from 'core/containers/App/App';


let middleware = [timeoutScheduler];
if (process.env.NODE_ENV !== 'production') {
  console.log("Developpement : add logger and crashreporter")
  middleware = [...middleware, logger, crashReporter]
}

const store = createStore<any>({
    enhancers: [applyMiddleware(...middleware)],
    extensions: [getSagaExtension()]
    },
    CoreStore,
    AuthStore
);

const Root = () => (
    <Provider store={store}>
      <Router history={history} >
        <App />
      </Router>
    </Provider>
);

render(<Root />, document.getElementById("root"));