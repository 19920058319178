import { Article } from "main/models/Article";

export const INIT = 'INIT'
export interface init {
    type: typeof INIT,
}

export const GETBYID_REQUEST = 'GETBYID_REQUEST'
export interface getbyid_request {
    type: typeof GETBYID_REQUEST,
    payload: {
        key: string,
    }
}

export const GETBYID_SUCCESS = 'GETBYID_SUCCESS'
export interface getbyid_success {
    type: typeof GETBYID_SUCCESS,
    payload: {
        article: Article,
    }
}

export type ActionsTypes = init | getbyid_request | getbyid_success;