import React from 'react';
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from 'react-router';
import * as mainRoutes from './mainRoutes'
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { MainStore } from './store';
import Layout from './layout';

export const MainModuleUrl = "/main";



const Main:React.FC<RouteComponentProps> =  () => (
    <Layout>
        <Switch>
        <Route exact path={`${MainModuleUrl}/latest`} component={mainRoutes.Latest} />
        <Route exact path={`${MainModuleUrl}/search`} component={mainRoutes.Search} />
        <Route exact path={`${MainModuleUrl}/article/:numart`} component={mainRoutes.GetById} />
        <Redirect from={`${MainModuleUrl}`}  to={`${MainModuleUrl}/latest`}/>
        </Switch>
    </Layout>
)

const MainWithRouter = withRouter(Main);

export const MainModule = () => {
  return (
      <DynamicModuleLoader modules={[MainStore]}>
          <MainWithRouter />
      </DynamicModuleLoader>
  );
}