import { ParamsState } from './types';
import * as ParamsActions from '../actions/Params/paramsTypes';

const initialState : ParamsState = {
  pending: false,
  publication:"QB"
};

const paramsReducer = (state = initialState, action: ParamsActions.paramsTypes): ParamsState  => {

  switch (action.type) {
    
    case ParamsActions.LOAD_PARAMS:
        return {
          ...state,
          pending: true
    };

    case ParamsActions.LOAD_PARAMS_SUCCESS:
        return {
          ...state,
          params: action.payload.params,
          pending: false
    };

    case ParamsActions.LOAD_PARAMS_ERROR:
        return {
          ...state,
          params: undefined,
          pending: false
    };

    case ParamsActions.SET_PUBLICATION:
        return {
          ...state,
          publication: action.payload.publication,
    };
    
    default:
      return state;
  }
};

export default paramsReducer;