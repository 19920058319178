import { SortType } from './../../models/Sort-Type';
import * as searchActions from '../actions/search/types';
import * as ErrorActions from 'core/store/actions/Error/errorTypes';
import { call, put, takeLatest, select  } from 'redux-saga/effects'

import ArticlesService from 'main/services/articles.service';
import { IMainModuleState } from '../reducers/types';
import { AccessToken } from 'auth/models/token';
import history from 'auth/config/history';
import { getView } from 'shared/constants';

function* _request(action: searchActions.search_request) {
    try {
        let go = !!action.payload.go || action.payload.go === undefined;

        if (!!go) history.push('/main/search');

        if (!action.payload.text || action.payload.text.length === 0) {
            return yield put({type: searchActions.SEARCH_SUCCESS, payload: {page:1, results:[]}});
        }

        const access:AccessToken = yield select((state:IMainModuleState) => state.authState.token?.accessToken);

        let publication = "";
        if (getView() === "SGP") {
            publication = yield select((state:IMainModuleState) => state.paramsState.publication);
        }

        const page:number = action.payload.init ? 1 :  (yield select((state:IMainModuleState) => state.searchState.page)) + 1;
        const sort:SortType = yield select((state:IMainModuleState) => state.searchState.sort);

        const results = yield call(ArticlesService.search, action.payload.text,page, publication, sort, access);
        console.log(results)
        yield put({type: searchActions.SEARCH_SUCCESS, payload: {page, results}});
    } catch (e) {
       yield put({type: ErrorActions.CHECK_ERROR,  payload: {error: e}});
    }
}

function* _more(action: searchActions.search_request) {
    try {

        const text:string = yield select((state:IMainModuleState) => state.searchState.searchTxt);

        yield put({type: searchActions.SEARCH_REQUEST, payload: {text}});

    } catch (e) {
       yield put({type: ErrorActions.CHECK_ERROR,  payload: {error: e}});
    }
}

function* _sort(action: searchActions.change_sort) {
    try {
        yield call([localStorage, localStorage.setItem],"search_sort", JSON.stringify(action.payload.sort));
        const text:string = yield select((state:IMainModuleState) => state.searchState.searchTxt);
        yield put({type: searchActions.SEARCH_REQUEST, payload: {text, init:true}});

    } catch (e) {
       yield put({type: ErrorActions.CHECK_ERROR,  payload: {error: e}});
    }
}

function* searchSaga() {
    yield takeLatest(searchActions.SEARCH_REQUEST, _request);
    yield takeLatest(searchActions.MORE_RESULTS, _more);
    yield takeLatest(searchActions.CHANGE_SORT, _sort);
}


export default searchSaga;