import { getView } from 'shared/constants';
import * as latestActions from './../actions/latest/types';
import * as ErrorActions from 'core/store/actions/Error/errorTypes';
import { call, put, takeLatest, select  } from 'redux-saga/effects'

import ArticlesService from 'main/services/articles.service';
import { IMainModuleState } from '../reducers/types';
import { AccessToken } from 'auth/models/token';

import moment from 'moment';

function* _request(action: latestActions.getlatest_request) {
    try {
        const access:AccessToken = yield select((state:IMainModuleState) => state.authState.token?.accessToken);

        let publication = "";
        if (getView() === "SGP") {
            publication = yield select((state:IMainModuleState) => state.paramsState.publication);
        }


        const latest = yield call(ArticlesService.latest, 0, moment().format('YYYY-MM-DD'),publication, access);
        
        yield put({type: latestActions.GET_LASTDATE, payload: {date:latest.date}});
        yield put({type: latestActions.GETLATEST_SUCCESS, payload: {latest}});
    } catch (e) {
       yield put({type: ErrorActions.CHECK_ERROR,  payload: {error: e}});
    }
}

function* _next(action: latestActions.getlatest_next) {
    try {
        const access:AccessToken = yield select((state:IMainModuleState) => state.authState.token?.accessToken);
        const date:string = yield select((state:IMainModuleState) => state.latestState.date);
        let publication = "";
        if (getView() === "SGP") {
            publication = yield select((state:IMainModuleState) => state.paramsState.publication);
        }

        const latest = yield call(ArticlesService.latest, 1, moment(date).format('YYYY-MM-DD'),publication, access);
        yield put({type: latestActions.GETLATEST_SUCCESS, payload: {latest}});
    } catch (e) {
       yield put({type: ErrorActions.CHECK_ERROR,  payload: {error: e}});
    }
}

function* _prev(action: latestActions.getlatest_prec) {
    try {
        console.log('_prev')
        const access:AccessToken = yield select((state:IMainModuleState) => state.authState.token?.accessToken);
        const date:string = yield select((state:IMainModuleState) => state.latestState.date);
        let publication = "";
        if (getView() === "SGP") {
            publication = yield select((state:IMainModuleState) => state.paramsState.publication);
        }
        const latest = yield call(ArticlesService.latest, -1, moment(date).format('YYYY-MM-DD'),publication, access);
        yield put({type: latestActions.GETLATEST_SUCCESS, payload: {latest}});
    } catch (e) {
       yield put({type: ErrorActions.CHECK_ERROR,  payload: {error: e}});
    }
}

function* latestSaga() {
    yield takeLatest(latestActions.GETLATEST_REQUEST, _request);
    yield takeLatest(latestActions.GETLATEST_NEXT, _next);
    yield takeLatest(latestActions.GETLATEST_PREC, _prev);
}


export default latestSaga;