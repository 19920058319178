import { Constants } from 'shared/constants';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

export const StyledDialog = withStyles({
    root: {
      background: Constants.colorPrimary,// 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      //opacity:0.9
    },
    paper: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
  })(Dialog);

export const ModalContent = styled.div`
    color:${Constants.colorText};
    text-align:center;
    margin:10px;
`

export const ModalText= styled.div`
    margin-top:10px;
    color:${Constants.colorText};
    animation: ${Constants.colorblink} 1s infinite;
`

