import { AuthState } from './types';
import * as AuthActions from '../actions/Auth/AuthTypes';
import * as ApiActions from '../actions/Api/ApiTypes';


const initialState : AuthState = {
    isReady:false
};

const authReducer = (state = initialState, action: AuthActions.AuthActionsTypes |  ApiActions.ApiActionsTypes): AuthState  => {

  switch (action.type) {
    

    case AuthActions.INIT_END:
      return {
        ...state,
        isReady:true
    };

    case ApiActions.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        isReady:true
    };

    case ApiActions.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        isReady:true
    };

    case AuthActions.LOGOUT:
        return {
          ...state,
          token: undefined
    };
    
    default:
      return state;
  }
};

export default authReducer;