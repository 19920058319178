import * as getbyidActions from './../actions/getbyid/types';
import * as ErrorActions from 'core/store/actions/Error/errorTypes';
import * as ParamsActions from 'core/store/actions/Params/paramsTypes';
import { call, put, takeLatest, select  } from 'redux-saga/effects'

import ArticlesService from 'main/services/articles.service';
import { IMainModuleState } from '../reducers/types';
import { AccessToken } from 'auth/models/token';

function* _request(action: getbyidActions.getbyid_request) {
    try {
        const access:AccessToken = yield select((state:IMainModuleState) => state.authState.token?.accessToken);
        const article = yield call(ArticlesService.getbyid, action.payload.key, access);
        console.log(article.id.substring(0,2));
        yield put({type: ParamsActions.SET_PUBLICATION, payload: {publication:article.id.substring(0,2)}});
       yield put({type: getbyidActions.GETBYID_SUCCESS, payload: {article}});
    } catch (e) {
       yield put({type: ErrorActions.CHECK_ERROR,  payload: {error: e}});
       yield put({type: getbyidActions.INIT});
    }
}

function* getbyidSaga() {
    yield takeLatest(getbyidActions.GETBYID_REQUEST, _request);
}


export default getbyidSaga;