import { call, put, takeLatest, select  } from 'redux-saga/effects' //takeEvery
import AuthService from 'auth/services/auth.service';
import * as LoginActions from '../actions/Login/LoginTypes';
import * as ApiActions from '../actions/Api/ApiTypes';
import * as AuthActions from '../actions/Auth/AuthTypes';
import { User } from 'auth/models/user';
import { IAuthModuleState } from '../reducers/types';

function* _login(action: LoginActions.login_request) {
    try {
        const token =   yield call(AuthService.login, action.payload.credentials);
        //    const user = yield call(AuthService.load, action.payload.credentials.username);
       yield call([localStorage, localStorage.setItem],"remember", JSON.stringify(action.payload.credentials.remember));
       if (action.payload.credentials.remember) {
            yield call([localStorage, localStorage.setItem],"currentToken", JSON.stringify(token));
       } else {
            yield call([sessionStorage, sessionStorage.setItem],"currentToken", JSON.stringify(token));
       }
        yield put({type: ApiActions.LOGIN_SUCCESS, payload: {token}});
    } catch (e) {
       yield put({type: ApiActions.LOGIN_FAILURE, error: "Login ou/et mot de passe incorrects"});
    }
}

function* _refresh(action: LoginActions.login_request) {
    try {
        const token = yield select((state:IAuthModuleState) => state.authState.token);
        const remember =  JSON.parse(yield call([localStorage, localStorage.getItem], "remember")) as boolean;

        const new_token = yield call(AuthService.refreshToken, token);
       if (remember) {
            yield call([localStorage, localStorage.setItem],"currentToken", JSON.stringify(new_token));
       } else {
            yield call([sessionStorage, sessionStorage.setItem],"currentToken", JSON.stringify(new_token));
       }
        yield put({type: ApiActions.REFRESH_TOKEN_SUCCESS, payload: {token:new_token}});
    } catch (e) {
       yield put({type: ApiActions.REFRESH_TOKEN_FAILURE, error: "token refresh failed, logging out user"});
    }
}

function* _init() {
    try {
        const remember =  JSON.parse(yield call([localStorage, localStorage.getItem], "remember")) as boolean;
        let token;
       if (remember) {
        token = JSON.parse(yield call([localStorage, localStorage.getItem], "currentToken")) as User;
       } else {
        token = JSON.parse(yield call([sessionStorage, sessionStorage.getItem], "currentToken")) as User;
       }
       console.log(token);
       if (token) {
           yield put({type: ApiActions.LOGIN_SUCCESS, payload: {token}});
       } else {
        yield put({type: AuthActions.INIT_END});
       }
    } catch (e) {
       yield put({type: ApiActions.LOGIN_FAILURE, error: e.message});
    }
}

function* _logout() {
    try {
        const remember =  JSON.parse(yield call([localStorage, localStorage.getItem], "remember")) as boolean;
        if (remember) {
                yield call([localStorage, localStorage.removeItem], "currentToken")
        } else {
                yield call([sessionStorage, sessionStorage.removeItem], "currentToken")
        }
        yield call([localStorage, localStorage.removeItem], "remember")
        yield call([localStorage, localStorage.removeItem], "profile")

    } catch (e) {

    }
}


function* authSaga() {
    yield takeLatest(LoginActions.LOGIN_REQUEST, _login);
    yield takeLatest(AuthActions.REFRESH_TOKEN, _refresh);
    yield takeLatest(AuthActions.INIT, _init);
    yield takeLatest(AuthActions.LOGOUT, _logout);
}

export default authSaga;