import { Constants } from 'shared/constants';
import Axios from 'axios';
import { Article } from 'main/models/Article';
import { AccessToken } from 'auth/models/token';
import { SortType } from 'main/models/Sort-Type';

export default class ArticlesService {

    public static async latest(num:number, datePrev:string, publication:string, access:AccessToken): Promise<{date:string, results:Article[]}> {

        const config = {
            headers: { Authorization: `Bearer ${access.token}` }
        };

        return Axios.post(Constants.apiArticles + '/find/latest', 
          {num, datePrev, publication},
          config
        )
        .then((result) => {
            if (result.status === 200) {
                return Promise.resolve(result.data);
              } else {
                throw  new Error("GetLatest: a problem occured !");
              }
        })
        .catch(error => {
            throw error.response;
        });
    }

    public static async getbyid(key:string, access:AccessToken): Promise<Article> {

      const config = {
          headers: { Authorization: `Bearer ${access.token}` }
      };

      return Axios.post(Constants.apiArticles + '/find/byid', 
        {key},
        config
      )
      .then((result) => {
          if (result.status === 200) {
              return Promise.resolve(result.data.article);
            } else {
              throw  new Error("getbyid: a problem occured !");
            }
      })
      .catch(error => {
          throw error.response;
      });
  }

  public static async search(query:string, page:number, publication:string, sort:SortType, access:AccessToken): Promise<Article[]> {

    const config = {
        headers: { Authorization: `Bearer ${access.token}` }
    };

    return Axios.post(Constants.apiArticles + '/find/search', 
      {query, page, publication, sort},
      config
    )
    .then((result) => {
        if (result.status === 200) {
            return Promise.resolve(result.data.results);
          } else {
            throw  new Error("getbyid: a problem occured !");
          }
    })
    .catch(error => {
        throw error.response;
    });
}
}