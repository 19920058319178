import { call, put, takeLatest  } from 'redux-saga/effects' //takeEvery
import * as ParamsActions from '../actions/Params/paramsTypes';
import * as ErrorActions from '../actions/Error/errorTypes';
import ParamsService from 'core/services/params.service';

function* _load() {
    try {
       const params = yield call(ParamsService.getAll)
       yield put({type: ParamsActions.LOAD_PARAMS_SUCCESS, payload: {params: params}});
    } catch (e) {
        yield put({type: ParamsActions.LOAD_PARAMS_ERROR, payload: {error: e}});
        yield put({type: ErrorActions.SET_ERROR, payload: {error: e}});
    }
}


function* paramsSaga() {
    yield takeLatest(ParamsActions.LOAD_PARAMS, _load);
}

export default paramsSaga;