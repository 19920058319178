import { Middleware } from "redux";
// https://stackoverflow.com/questions/51198476/how-to-use-the-cancel-function-in-redux-timeoutscheduler-middleware-docs-examp
export const timeoutScheduler : Middleware = store => next => action => {
    if (!action.meta || !action.meta.delay) {
      return next(action)
    }
  
    const timeoutId = setTimeout(() => next(action), action.meta.delay)
  
    return function cancel() {
      clearTimeout(timeoutId)
    }
}