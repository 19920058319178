

import counterReducer from "./reducers/counterReducers";
import { IMainModuleState } from "./reducers/types";
import { ISagaModule } from "redux-dynamic-modules-saga";
import searchReducer from "./reducers/searchReducer";
import latestReducer from "./reducers/latestReducer";
import getbyIdReducer from "./reducers/getbyidReducer";
import latestSaga from "./sagas/latest.saga";
import getbyidSaga from "./sagas/getbyid.saga";

import {getlatest_request} from './actions/latest/actions';
import searchSaga from "./sagas/search.saga";


const mainStore: ISagaModule<IMainModuleState> = {
    id: "main",
    reducerMap: {
        counterState: counterReducer,
        searchState: searchReducer,
        latestState: latestReducer,
        getByIdState: getbyIdReducer,
    } as any,
    initialActions: [getlatest_request()],
    sagas: [latestSaga, getbyidSaga, searchSaga],
};

export const MainStore = mainStore;