
import { ICoreModuleState } from "./reducers/types";
import { ISagaModule } from "redux-dynamic-modules-saga";
import errorReducer from "./reducers/errorReducer";
import paramsReducer from "./reducers/paramsReducer";
import paramSaga from "./sagas/paramsSaga";
//import { load_params } from "./actions/Params/paramsActions";

const coreStore: ISagaModule<ICoreModuleState> = {
    id: "core",
    reducerMap: {
        errorState: errorReducer,
        paramsState: paramsReducer,
    } as any,
    initialActions: [],
    sagas: [paramSaga],
};

export const CoreStore = coreStore;