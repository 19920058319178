import { Constants } from 'shared/constants';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const StyledBottomContent = styled.div`
  margin: 0px auto; 
  padding: 0px; 
  color: ${Constants.colorTextFooter}; 
  max-width: 335px; 
  font-size: 10px; 
  line-height: 2;
`;

export const StyledBottomLink = styled.a`
  color: ${Constants.colorLinkFooter};
`;

export const StyledAppBar = withStyles({
    root: {
      bottom:0,
      top:'auto',
      padding: '4px 12px',
      boxSizing: 'border-box',
      backgroundColor: Constants.colorBgFooter,
      textAlign: 'center',
    }
})(AppBar);

export const StyledGrid = styled(Grid)<{height: string}>`
  min-height: ${props => (props.height)}
`

export const Styles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));