import { Params } from "core/models/params"

export const LOAD_PARAMS = 'LOAD_PARAMS'
export interface load_params {
    type: typeof LOAD_PARAMS
}

export const LOAD_PARAMS_SUCCESS = 'LOAD_PARAMS_SUCCESS'
export interface load_params_success {
    type: typeof LOAD_PARAMS_SUCCESS,
    payload:{params: Params}
}

export const LOAD_PARAMS_ERROR = 'LOAD_PARAMS_ERROR'
export interface load_params_error {
    type: typeof LOAD_PARAMS_ERROR,
    payload:{error: any}
}

export const SET_PUBLICATION = 'SET_PUBLICATION'
export interface set_publication {
    type: typeof SET_PUBLICATION,
    payload:{publication: string}
}

export type paramsTypes = load_params | load_params_success | load_params_error | set_publication