import Axios from 'axios';
import { Params } from 'core/models/params';
import { Constants } from 'shared/constants';

export default class ParamsService {

    public static async getAll(): Promise<Params[]> {
        return Axios.get(Constants.apiIdentity  + '/list')
        .then((result) => {
            return result.data.data;
        });
    }
}