import { ErrorState } from './types';
import * as ErrorActions from '../actions/Error/errorTypes';

const initialState : ErrorState = {
    error:''
};

const errorReducer = (state = initialState, action: ErrorActions.ErrorActionsTypes): ErrorState  => {

  switch (action.type) {
    
    case ErrorActions.SET_ERROR:
        return {
          ...state,
          error: action.payload.message ? action.payload.message : action.payload.error.toString()
    };
    
    default:
      return state;
  }
};

export default errorReducer;