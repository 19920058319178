import { Token } from "auth/models/token";


export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export interface login_success {
    type: typeof LOGIN_SUCCESS,
    payload: {
        token: Token,
    }
}

export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export interface login_failure {
    type: typeof LOGIN_FAILURE,
    error: any
}



export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export interface refresh_token_success {
    type: typeof REFRESH_TOKEN_SUCCESS,
    payload: {
        token: Token,
    }
}

export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE'
export interface refresh_token_failure {
    type: typeof REFRESH_TOKEN_FAILURE,
    payload: {
        error: any,
    }
}

export type ApiActionsTypes = login_success | login_failure | refresh_token_success | refresh_token_failure