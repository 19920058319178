import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { withStyles, Theme } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { Constants } from 'shared/constants';

export const StyledDiv = styled.div`
    flex-grow: 1
`;


export const StyledTextFieldForm = withStyles({
    root: {
        margin: '8px 16px',
        '& label.Mui-focused': {
            color: Constants.colorPrimary,
        },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: 'yellow',
        // },
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            //     borderColor: 'white',
            // },
            // '&:hover fieldset': {
            //     borderColor: 'red',
            // },
            '&.Mui-focused fieldset': {
                borderColor: Constants.colorPrimary,
            }
        }
    }
})(TextField);

export const StyledFormGroup = withStyles({
    root: {
        margin: '4px 16px',
    }
})(FormGroup);

export const StyledRedButton = withStyles({
    root: {
        backgroundColor: Constants.colorRed,
        color: Constants.colorText,
        '&:hover': {
          backgroundColor: Constants.colorRedDarker,
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: Constants.colorRedActive,
        },
        '&:focus': {
          boxShadow: '0 0 0 0.2rem '+ Constants.colorRedLight,
        },
    },
  })(Button);

  export const StyledBottomButton = withStyles((theme: Theme) => ({
    root: {
      fontSize: '0.75rem',
      lineHeight: '1.5',
      color:'white',
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#333"
      }
    },
    outlined:{
      borderColor:'#FAFAFA',
      padding:'2px 12px'
      
    }
  }))(Button);