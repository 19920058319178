import { SearchState } from './types';
import * as searchActions from '../actions/search/types';
import { SortType } from 'main/models/Sort-Type';

const getInitialSort = () => {
  if (window.localStorage['search_sort']) return JSON.parse(window.localStorage['search_sort']) as SortType
  else  return SortType.pertinence;
}


const initialState : SearchState = {
    loading: false,
    results:[],
    page:0,
    sort:getInitialSort()
};

const searchReducer = (state = initialState, action: searchActions.ActionsTypes): SearchState  => {

  switch (action.type) {
    
    case searchActions.SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        page:action.payload.init ? 0 : state.page,
        searchTxt:action.payload.text
    };

    case searchActions.SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        page:action.payload.page,
        results: action.payload.page === 1 ? action.payload.results : [...state.results, ...action.payload.results]
    };
    
    case searchActions.CHANGE_SORT:
      return {
        ...state,
        sort: action.payload.sort
    };

    default:
      return state;
  }
};

export default searchReducer;