import * as getLatestTypes from './types';
import { Article } from "main/models/Article";

export function getlatest_request(): getLatestTypes.ActionsTypes {
  return {
    type: getLatestTypes.GETLATEST_REQUEST,
  }
}

export function getlatest_prec(): getLatestTypes.ActionsTypes {
  return {
    type: getLatestTypes.GETLATEST_PREC,
  }
}

export function getlatest_next(): getLatestTypes.ActionsTypes {
  return {
    type: getLatestTypes.GETLATEST_NEXT,
  }
}

export function getlatest_success(latest:{date:string, results:Article[]}): getLatestTypes.ActionsTypes {
  return {
    type: getLatestTypes.GETLATEST_SUCCESS,
    payload: {
      latest
    }
  }
}

export function get_lastdate(date:string): getLatestTypes.ActionsTypes {
  return {
    type: getLatestTypes.GET_LASTDATE,
    payload: {
      date
    }
  }
}



