import { AuthActionsTypes, INIT, LOGOUT, INIT_END, REFRESH_TOKEN } from './AuthTypes';


export function init(): AuthActionsTypes {
    return {
      type: INIT
    }
}

export function init_end(): AuthActionsTypes {
  return {
    type: INIT_END
  }
}

export function refresh_token(): AuthActionsTypes {
  return {
    type: REFRESH_TOKEN,
  }
}

export function logout(): AuthActionsTypes {
  return {
    type: LOGOUT
  }
}