// import React from 'react';
// import Loadable from "react-loadable";

// export const Login = Loadable({
//     loader: () => import("./containers/login/login"),
//     loading: Loading,
//     delay: 300,
// });

// function Loading(props:any) {
//     const {
//         isLoading,
//         pastDelay,
//         error,
//     } = props;
    
//     if (!isLoading && pastDelay) {
//         return <p>Loading...</p>;
//     } else if (error && !isLoading) {
//         return <p>Error!</p>;
//     } else {
//         return null;
//     }
// }

import loadable from '@loadable/component'

export const Login = loadable(() => import("./containers/login/login"));