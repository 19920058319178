import { Article } from "main/models/Article";

export const GETLATEST_REQUEST = 'GETLATEST_REQUEST'
export interface getlatest_request {
    type: typeof GETLATEST_REQUEST,
}

export const GETLATEST_PREC = 'GETLATEST_PREC'
export interface getlatest_prec {
    type: typeof GETLATEST_PREC,
}

export const GETLATEST_NEXT = 'GETLATEST_NEXT'
export interface getlatest_next {
    type: typeof GETLATEST_NEXT,
}

export const GETLATEST_SUCCESS = 'GETLATEST_SUCCESS'
export interface getlatest_success {
    type: typeof GETLATEST_SUCCESS,
    payload: {
        latest: {date:string, results:Article[]},
    }
}

export const GET_LASTDATE = 'GET_LASTDATE'
export interface get_lastdate {
    type: typeof GET_LASTDATE,
    payload: {
        date: string,
    }
}

export type ActionsTypes = getlatest_request | getlatest_success | get_lastdate | getlatest_prec | getlatest_next;