import { CounterState } from './types';
import * as CounterActions from '../actions/counter/types';

const initialState : CounterState = {
  counter: 0,
  loading: false,
  error: '',
};

const counterReducer = (state = initialState, action: CounterActions.CounterActionsTypes): CounterState  => {

  switch (action.type) {
    
    case CounterActions.INIT:
      return {
        ...state,
        loading: true,
    };

    case CounterActions.REQUEST_SUCCESS:
      return {
        ...state,
        counter: action.payload.count,
        loading: false,
    };

    case CounterActions.REQUEST_FAIL:
      return {
        ...state,
        error: action.error,
    };
    
    default:
      return state;
  }
};

export default counterReducer;