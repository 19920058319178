import React from 'react';
import { CenterGrid } from './styles';
import { StyledRedButton } from 'shared/styles';

const ErrorServer:React.FC = () => {

  const handleClick = () => {
    window.location.reload();
  }

  return (
    <CenterGrid container direction="column" justify="center" alignItems="center" >
      <div style={{ padding: 20 }}>--- Serveur indisponible ---</div>
      <StyledRedButton variant="contained" color="default" onClick={handleClick}>ESSAYER DE NOUVEAU</StyledRedButton>
    </CenterGrid>
  );
}


export default ErrorServer;
