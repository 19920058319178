import { set_error } from 'core/store/actions/Error/errorActions';
import { takeEvery, race, take, put } from 'redux-saga/effects';
import { logout, refresh_token } from '../actions/Auth/AuthActions';
import { IAction } from 'core/models/action';
import history from 'auth/config/history';

const ignoreActionTypes = ['REFRESH_TOKEN', 'LOGIN']

function monitorableAction(action:IAction) {
    return action.type
      .includes('REQUEST') && ignoreActionTypes
      .every(fragment => !action.type.includes(fragment))
}

function identifyAction(action:IAction) {
    return action.type.split('_').slice(0, -1).join('_')
}

function getSuccessType(action:IAction) {
    return `${identifyAction(action)}_SUCCESS`
}

function* _monitor(monitoredAction:IAction) {
    console.log('started monitoring: ' + monitoredAction.type);
    const { fail } = yield race({
        success: take(getSuccessType(monitoredAction)),
        fail: take('CHECK_ERROR'),
    });

    if (fail && fail.payload.error.status === 401) {
        console.log('detected 401, refreshing token')
        yield put(refresh_token());

        const { success } = yield race({
            success: take('REFRESH_TOKEN_SUCCESS'),
            fail: take('REFRESH_TOKEN_FAILURE'),
        }) 
    
        if (success) {
            console.log('token refreshed, retrying '+ monitoredAction.type)
            yield put(monitoredAction)
        } else {
            console.log('token refresh failed, logging out user')
            yield put(logout())
        }

    } else if (fail && fail.payload.error.status !== 401){
        console.log(fail.payload.error)
        let message = `${fail.payload.error.status}: ${fail.payload.error.statusText}`
        if (fail.payload.error.data && fail.payload.error.data.errors && fail.payload.error.data.errors.length > 0) {
            message =  fail.payload.error.data.errors[0].description;
        }
        yield put(set_error(fail.payload.error, message));
        history.replace('/main/latest')

    }

    console.log('monitoring: '+monitoredAction.type+ ' finished')
} 

function* monitorSaga() {
    yield takeEvery(monitorableAction, _monitor)
}


export default monitorSaga;