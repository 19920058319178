 import React from 'react';

import { Redirect, Switch } from "react-router-dom";
import { AuthModule, AuthModuleUrl } from 'auth/authModule';
import { MainModule, MainModuleUrl } from 'main/mainModule';

import LoggedRoute from 'auth/hoc/LoggedRouteHOC';
import NotLoggedRoute from 'auth/hoc/NotLoggedRouteHOC';
import Waiting from 'core/components/waiting';
import ToastManager from 'core/components/toast-manager';
import { ToastContainer } from 'core/components/toast-manager/toast-container';
import { connect } from 'react-redux';
import { IAuthModuleState } from 'auth/store/reducers/types';
import { ToastProvider} from 'react-toast-notifications';
import { set_error } from 'core/store/actions/Error/errorActions';

import './App.scss';
import ErrorServer from '../../components/error-server';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { theme } from 'shared/constants';

interface IProps {
  isready: boolean,
  error?: string,
  notconnected: boolean,
  set_error:typeof set_error
}


const App:React.FC<IProps> = ({isready, error, notconnected, set_error}) => {

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider components={{ToastContainer:ToastContainer}}>
        <div className="App">
          <Waiting pending={!isready}></Waiting>
          <ToastManager error={error} set_error={set_error}/>
          {notconnected? 
            <ErrorServer></ErrorServer>
            :
            <Switch>
              <LoggedRoute path={`${MainModuleUrl}`} component={MainModule} />
              <NotLoggedRoute path={`${AuthModuleUrl}`}  component={AuthModule} />
              <Redirect from='/' to={`${MainModuleUrl}`}/>
            </Switch>
          }
        </div>
      </ToastProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: IAuthModuleState) => ({
  isready: state.authState.isReady && !state.paramsState.pending,
  error: state.errorState.error,
  notconnected: false //state.paramsState.params === undefined
});

const mapDispatchToProps = {
  set_error:set_error
};


export default connect(mapStateToProps, mapDispatchToProps)(App);
