import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteProps, Redirect } from 'react-router';
//import history from 'auth/config/history';
import { IAuthModuleState } from 'auth/store/reducers/types';
import { isLogged } from 'auth/store/selectors/auth.selector';

interface Props extends RouteProps {
  authenticated?: boolean;
}


const LoggedRouteHOC = ({ component, ...rest }: Props) => {

  if (!component) {
    throw Error("component is undefined");
  }

  if (!rest.authenticated) {
    return <Redirect to={{ pathname: '/auth/login' }} />
  } else {
    return <Route {...rest} component={component} />
  }
}

function mapStateToProps(state: IAuthModuleState) {
  return {
    authenticated: isLogged(state)
  }
}

export default connect(mapStateToProps)(LoggedRouteHOC);