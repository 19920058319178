import { LatestState } from './types';
import * as latestActions from '../actions/latest/types';

const initialState : LatestState = {
    loading: false,
    last:'',
    date:'',
    latest:[]
};

const latestReducer = (state = initialState, action: latestActions.ActionsTypes): LatestState  => {

  switch (action.type) {
    
    case latestActions.GETLATEST_REQUEST:
      return {
        ...state,
        loading: true,
    };

    case latestActions.GETLATEST_PREC:
      return {
        ...state,
        loading: true,
    };

    case latestActions.GETLATEST_NEXT:
      return {
        ...state,
        loading: true,
    };

    case latestActions.GETLATEST_SUCCESS:
      return {
        ...state,
        loading: false,
        date:action.payload.latest.date,
        latest:action.payload.latest.results
    };

    case latestActions.GET_LASTDATE:
      return {
        ...state,
        loading: false,
        last:action.payload.date
    };
    
    default:
      return state;
  }
};

export default latestReducer;