import { LoginState } from './types';
import * as LoginActions from '../actions/Login/LoginTypes';
import * as ApiActions from '../actions/Api/ApiTypes';

const initialState : LoginState = {
  pending: false,
  error: '',
};

const loginReducer = (state = initialState, action: ApiActions.ApiActionsTypes | LoginActions.LoginActionsTypes): LoginState  => {

  switch (action.type) {
    
    case LoginActions.LOGIN_REQUEST:
      return {
        ...state,
        pending: true,
        error:''
    };

    case ApiActions.LOGIN_SUCCESS:
        return {
            ...state,
            pending: false,
            error:''
    };

    case ApiActions.LOGIN_FAILURE:
        return {
            ...state,
            pending: false,
            error: action.error
    };

    case LoginActions.SET_LOGIN_ERROR:
      return {
        ...state,
        error: action.payload.error
    };

    default:
      return state;
  }
};

export default loginReducer;