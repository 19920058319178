import authReducer from "./reducers/AuthReducers";
import { ISagaModule } from "redux-dynamic-modules-saga";
import { IAuthModuleState } from "./reducers/types";
import loginReducer from "./reducers/LoginReducer";
import authSaga from "./sagas/auth.saga";
import monitorSaga from "./sagas/monitor.saga";

import { init } from "./actions/Auth/AuthActions";

const authStore: ISagaModule<IAuthModuleState> = {
    id: "auth",
    reducerMap: {
        authState: authReducer,
        loginState: loginReducer,
    } as any,
    initialActions: [init()],
    sagas: [authSaga, monitorSaga],
};

export const AuthStore = authStore;