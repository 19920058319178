import React, { useState } from 'react';
import { StyledAppBar, StyledGrid, StyledNavLink} from './styles';
import { StyledDiv } from 'shared/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AccountCircle } from '@material-ui/icons'
import { Constants } from 'shared/constants';

interface IProps {
  height?:string
  logout?: Function
}

export const NavBar: React.FC<IProps> = ({height = "10px", logout}) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleDropdown = (currentTarget:null | HTMLElement) => {
    setDropdownOpen(!dropdownOpen);
    if (currentTarget) setAnchorEl(currentTarget);
  }

  const itemHandler = (func?:Function) => () => {
    toggleDropdown(anchorEl)
    if (func) func()
  }

  return (
    <StyledDiv>
      <StyledAppBar position="relative">
        <StyledGrid container direction="row" alignItems="center" justify="flex-end" height={height}>
          <div>
            <IconButton
              aria-owns={dropdownOpen ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={(event) => toggleDropdown(event.currentTarget)}
              color="inherit"
              size="small"
            >
              <AccountCircle             style={{width:24}}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={dropdownOpen}
              onClose={() => toggleDropdown(null)}
            >
              <MenuItem onClick={itemHandler(logout)}>Se déconnecter</MenuItem>
            </Menu>
        </div>
        </StyledGrid>
      </StyledAppBar>
    </StyledDiv>
  )
}