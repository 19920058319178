import React from 'react';

import { StyledBottomContent,StyledBottomLink, StyledGrid, StyledAppBar, Styles } from './styles';

interface IProps {
    height?:string
    fixed?:boolean
}

export default function FooterBar({ height = '24px', fixed = false}: IProps) {
    
    const classes = Styles();
  
    return (
      <div className={classes.root}>
        <StyledAppBar position={fixed?"fixed":"relative"}>
            <StyledGrid container direction="column" alignItems="center" justify="center" height={height}>
                <StyledBottomContent>
                    <StyledBottomLink href="http://www.sgpresse.fr" tabIndex={-1} >Je m'abonne</StyledBottomLink>&nbsp;|&nbsp;
                    <StyledBottomLink href="mailto:abonnement@sgpresse.fr" tabIndex={-1} >Contact</StyledBottomLink>&nbsp;|&nbsp;
                    <StyledBottomLink href="http://www.sgpresse.fr" tabIndex={-1} >Mentions légales</StyledBottomLink>
                </StyledBottomContent>
                <StyledBottomContent>
                        <>Copyright </>
                        <StyledBottomLink href="http://www.sgpresse.fr" tabIndex={-1} >Société Générale de Presse</StyledBottomLink>
                </StyledBottomContent>
            </StyledGrid>
        </StyledAppBar>
    </div>
    )
}