export const CHECK_ERROR = 'CHECK_ERROR'
export interface check_error {
    type: typeof CHECK_ERROR,
    payload:{error: any}
}

export const SET_ERROR = 'SET_ERROR'
export interface set_error {
    type: typeof SET_ERROR,
    payload:{error: any, message?:string}
}

export type ErrorActionsTypes = set_error | check_error