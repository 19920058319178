
export const INIT = 'INIT'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const INCREMENT = 'INCREMENT'
export const DECREMENT = 'DECREMENT'
export const REQUEST_FAIL = 'REQUEST_FAIL'

export interface init {
    type: typeof INIT
}

export interface request_success {
    type: typeof REQUEST_SUCCESS,
    payload: {
        count: number,
    }
}

export interface increment {
    type: typeof INCREMENT
}


export interface decrement {
    type: typeof DECREMENT
}

export interface requestFail {
    type: typeof REQUEST_FAIL
    error: any,
}


export type CounterActionsTypes = init | request_success | increment | decrement | requestFail;