import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import { withStyles, Theme, fade } from '@material-ui/core/styles';
import { styled as styledMaterial }  from '@material-ui/styles';
import { Link } from 'react-router-dom';

export const StyledMainContent = styled.div<{marginTop: string}>`
  margin-top: ${props => props.marginTop};
`;

export const StyledLink = styled(Link)<{isactive:string}>`
  text-decoration:none;
  color:rgba(0, 0, 0, 0.83);
  font-weight:${props => props.isactive === 'active'? 'bold':''};
  &:hover {
    text-decoration:underline;
  }
  outline:0;
`;


export const StyledInputSearch =  withStyles((theme: Theme) => ({
  root: {
    color: 'inherit',
    width: 'inherit',
    position: 'absolute',
    display: 'flex'
  },
  input: {
    padding: theme.spacing(1, 7, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    backgroundColor:'rgb(217,223,231)',
    // [theme.breakpoints.up('md')]: {
    //     width: 200,
    //   },
  },
}))(InputBase);

export const StyledSearchDiv = styledMaterial('div')<Theme>(props => ({
  position: 'relative',
  borderRadius: props.theme.shape.borderRadius,
  backgroundColor: fade(props.theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: fade(props.theme.palette.common.white, 0.25),
  },
  marginRight: props.theme.spacing(2),
  marginLeft: 0,
  marginBottom:6,
  width: '100%',
  textAlign:'end'
}));

export const StyledSearchIconDiv = styledMaterial('div')<Theme>(props => ({
  width: props.theme.spacing(7),
  height: '100%',
  position: 'relative',
  //pointerEvents: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


